import React from "react";
import { services } from "../components/CardData";
const About = () => {
  return (
    <div className=" bg-[#618264]    text-white flex flex-col items-center justify-center ">
      {/* SECTION 1  */}
      <div className="pt-10 ">
        {/* WHY US */}
        <h1 className=" text-2xl">
          ABOUT <span className="font-extrabold">US</span>
        </h1>
      </div>
      <div className="px-4 pb-10 pt-8 lg:px-12 xl:px-48 w-full ">
        <h1 className="  text-lg font-light leading-5 ">
          OUR <br />
          <span className="font-extrabold">COMPANY</span>
        </h1>
        <p className="mt-2 font-light text-base md:text-base leading-tight">
          We are a Goa-based renewable energy solutions company for homes and
          businesses, providing end-to-end support for installation and
          maintenance.
        </p>
      </div>
      {/* GREEN UNDERLINE  */}
      <div className="w-full flex justify-start lg:px-8 xl:px-44">
        <span className="ml-4 rounded-sm h-[6px] w-32 bg-[#79AC78]"></span>
      </div>
      {/* SECTION 2 */}
      <div className="px-4 py-10 w-full lg:px-12 xl:px-36 ">
        {/* "OUR SERVICES" TEXT  */}
        <div className="xl:flex xl:justify-center ">
          <h1 className="text-lg font-light leading-5 ">
            OUR <br /> <span className="font-extrabold">SERVICES</span>
          </h1>
        </div>

        {/* BULLET POINTS  */}
        <div className=" flex flex-col text-base md:pl-4 ">
          {services.map((item) => (
            <div
              key={item.id}
              className="flex flex-row  justify-start space-y-4 xl:justify-center "
            >
              <div className="flex items-center justify-center mr-4 ">
                <div className="flex items-center justify-center w-8 h-8  bg-[#79AC78] rounded-full font-bold">
                  {item.id}
                </div>
              </div>
              <div className=" flex flex-col  justify-center xl:w-[60%]">
                <h2 className="font-extrabold text-lg  ">{item.header}</h2>
                <p className=" font-light ">{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;

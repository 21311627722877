import React from "react";
import HeatPump from "../assets/heatpump.png";
const AirHeatPump = () => {
  return (
    <div className=" bg-gradient-to-r from-[#c2e5c5] via-gray-50 to-[#c2e5c5] text-white flex flex-col items-center justify-between h-full ">
      {/* SECTION 1  */}
      <div className="h-1/2 w-full bg-[#79AC78] py-10">
        {/* HEAT PUMP IMAGE  */}
        <div className="flex justify-center mb-4">
          <img src={HeatPump} alt=""></img>
        </div>
        <div className=" text-center py-6">
          <h1 className="text-2xl font-extrabold text-white ">
            Air Source <span className="text-black  ">Heat Pump </span>
          </h1>
          <p className="text-sm ">Commercial & Domestic</p>
        </div>
      </div>
      {/* SECTION 2  */}
      <div className="h-[400px] w-full flex flex-col gap-4  justify-evenly text-sm lg:px-14  ">
        <div className="flex justify-end">
          <div className=" flex items-center justify-center border  bg-[#79AC78] h-10 xl:w-[55%] w-[70%] rounded-r-full lg:rounded-full text-center ">
            <p>Constant Temperature Hot Water</p>
          </div>
        </div>

        {/* <div className="flex justify-end">
          <div className="flex justify-center items-center xl:w-[55%] w-[70%] border  bg-[#79AC78] h-10 rounded-l-full lg:rounded-full  ">
            <p>Electricity & Water Separated</p>
          </div>
        </div> */}

        <div className="flex items-center justify-center border  bg-[#79AC78] h-10 xl:w-[55%] w-[70%] rounded-r-full lg:rounded-full  text-center ">
          <p>High Efficiency & Energy Saving</p>
        </div>

        <div className="flex justify-end">
          <div className="flex justify-center items-center xl:w-[55%] w-[70%] border  bg-[#79AC78] h-10 rounded-l-full lg:rounded-full  ">
            <p>Environment Protection</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirHeatPump;

import React, { useEffect, useRef } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
// import Slider from "./components/Slider";
import About from "./pages/About";
import AirHeatPump from "./pages/AirHeatPump";
import Benefits from "./pages/Benefits";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import HybridSolar from "./pages/HybridSolar";
import WaterHeater from "./pages/WaterHeater";
import ImageGallery from "./pages/ImageGallery";
import Testimonial from "./pages/Testimonial";
import ReactGA from "react-ga4";

ReactGA.initialize("G-K1BZNV36BR");

function App() {
  const HomeRef = useRef(null);
  const AboutRef = useRef(null);
  const SliderRef = useRef(null);
  const HybridRef = useRef(null);
  const WaterRef = useRef(null);
  const AirRef = useRef(null);
  const BenefitsRef = useRef(null);
  const ContactRef = useRef(null);
  const GalleryRef = useRef(null);
  const TestimonialRef = useRef(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const handleClick = (section) => {
    const refMapping = {
      home: HomeRef,
      about: AboutRef,
      slider: SliderRef,
      hybrid: HybridRef,
      water: WaterRef,
      air: AirRef,
      benefits: BenefitsRef,
      contact: ContactRef,
      gallery: GalleryRef,
      testimonial: TestimonialRef,
    };

    const scrollOptions = {
      behavior: "smooth",
      block:
        section === "contact"
          ? "end"
          : "start" && section === "home"
          ? "start"
          : "start",
    };

    refMapping[section]?.current?.scrollIntoView(scrollOptions);
    refMapping["home"]?.current?.scrollTo({ top: 0 });
  };

  return (
    <div className="App relative w-full">
      <div ref={HomeRef}></div>
      <Navbar handler={handleClick} />
      <div>
        <Home handler={handleClick} />
      </div>
      <div ref={AboutRef}>
        <About />
      </div>
      <div ref={GalleryRef}>
        <ImageGallery handler={handleClick} />
      </div>
      <div ref={WaterRef}>
        <WaterHeater />
      </div>
      <div ref={HybridRef}>
        <HybridSolar />
      </div>
      {/* <div ref={SliderRef}>
        <Slider />
      </div> */}
      <div ref={AirRef}>
        <AirHeatPump />
      </div>
      <div ref={BenefitsRef}>
        <Benefits />
      </div>
      <div ref={TestimonialRef}>
        <Testimonial />
      </div>
      <div ref={ContactRef}>
        <Contact handler={handleClick} />
      </div>
    </div>
  );
}

export default App;

import React from "react";
import { benefits } from "../components/CardData";
const Benefits = () => {
  const colors = [
    "bg-[#618264]",
    "bg-[#79AC78]",
    "bg-[#7EC180]",
    "bg-[#9BBA9E]",
  ];
  return (
    <div className="bg-[#618264] text-white flex flex-col  items-center  justify-between h-full ">
      {/* SECTION 1  */}
      <div className="pt-10 ">
        {/* WHY US */}
        <h1 className=" text-2xl">
          WHY <span className="font-extrabold">US?</span>
        </h1>
      </div>
      {/* SECTION 2  */}
      <div className=" flex flex-col text-base pt-2 w-full ">
        {benefits.map((item, i) => (
          <div
            key={i}
            className={`flex  items-row justify-center  px-4 py-8 md:justify-start  xl:justify-center  ${
              colors[i % colors.length]
            } `}
          >
            <div className="flex xl:w-[70%]">
              <div className="flex items-center justify-start pr-4   ">
                <div className="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 bg-[#fff] text-black rounded-full font-bold">
                  {item.id}
                </div>
              </div>
              <div className="flex flex-col ">
                <h2 className="font-extrabold text-lg flex gap-[6px] text-black">
                  {item.header}{" "}
                  <span className="text-white">{item.header2}</span>
                </h2>
                <p className=" font-light flex">{item.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;

// {/* <div className="flex flex-col justify-center ">
//   {/* BULLET LIST  */}

//   {benefits.map((item, i) => (
//     <div
//       key={i}
//       className={`flex  items-center justify-center py-6   ${
//         colors[i % colors.length]
//       } `}
//     >
//       <div
//         key={item.id}
//         className="flex flex-row justify-between w-full py-4 px-4 "
//       >
//         <div className="flex items-center justify-center px-2">
//           <div className="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 bg-[#fff] text-black rounded-full font-bold">
//             <p>{item.id}</p>
//           </div>
//         </div>
//         <div className="text-base md:text-base  ">
//           <h2 className="font-extrabold text-lg">
//             {item.header} <span className="text-black">{item.header2}</span>
//           </h2>
//           <p className="font-light ">{item.text}</p>
//         </div>
//       </div>
//     </div>
//   ))}
// </div>; */}

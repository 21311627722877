export const benefits = [
  {
    id: 1,
    header: "All over",
    header2: "GOA",
    text: "We cater to homes and businesses statewide, providing tailored energy solutions for every need in Goa.",
  },
  {
    id: 2,
    header: "E2E",
    header2: "Service",
    text: "From consultation to installation and ongoing maintenance, our expert team provides end-to-end support for all your energy needs.",
  },
  {
    id: 3,
    header: "Cut",
    header2: "Energy Bills",
    text: "Our solar panels, water heaters, and heat pumps slash bills, offering long-term savings. We empower sustainability, reducing costs for homes and businesses.",
  },
  {
    id: 4,
    header: "Reliable",
    header2: "Power",
    text: "With our solar battery and stabilizer solutions, ensure uninterrupted energy supply for businesses and households, guaranteeing consistent performance.",
  },
];

export const services = [
  {
    id: 1,
    header: "Consultancy",
    text: " We empower customers with personalized guidance for informed energy choices.",
  },
  {
    id: 2,
    header: "Survey",
    text: " Our site surveys ensure optimal placement for maximum energy efficiency.",
  },
  {
    id: 3,
    header: "Installation",
    text: " Our expert technicians install with precision & care for seamless integration.",
  },
  {
    id: 4,
    header: "Servicing",
    text: " We provide post-installation care through regular servicing and efficient fixes.",
  },
];

import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const MenuItems = ({ menuIcons, handler, onItemClick }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (section, hasChildren) => {
    if (hasChildren) {
      setOpen(!open);
    } else {
      handler(section);
      onItemClick();
    }
  };

  return (
    <List>
      {menuIcons.map((item) => (
        <div key={item.text}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleClick(item.section, !!item.children)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
              {item.children && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
          </ListItem>
          {item.children && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.children.map((child) => (
                  <ListItem
                    key={child.text}
                    disablePadding
                    sx={{
                      pl: 1.5,
                    }}
                  >
                    <ListItemButton
                      onClick={() => handleClick(child.section, false)}
                    >
                      <ListItemIcon>{child.icon}</ListItemIcon>
                      <ListItemText primary={child.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </div>
      ))}
    </List>
  );
};

export default MenuItems;

import HomeIcon from "../assets/icons/homeIcon.png";
import GroupIcon from "../assets/icons/groupIcon.png";
import ProductIcon from "../assets/icons/productIcon.png";
import HybridSolarIcon from "../assets/icons/hybridSolarIcon.png";
import WaterHeaterIcon from "../assets/icons/solarIcon.png";
import AirHeaterIcon from "../assets/icons/heatIcon.png";
import ContactIcon from "../assets/icons/contactIcon.png";

const menuIcons = [
  {
    text: "Home",
    icon: <img src={HomeIcon} alt="home icon" width={25} />,
    section: "home",
  },
  {
    text: "Services",
    icon: <img src={GroupIcon} alt="about icon" width={25} />,
    section: "about",
  },
  {
    text: "Products",
    icon: <img src={ProductIcon} alt="product icon" width={25} />,
    section: "products",
    children: [
      {
        text: "Solar Water Heater",
        icon: <img src={WaterHeaterIcon} alt="water heater icon" width={25} />,
        section: "water",
      },
      {
        text: "Solar On Grid System",
        icon: <img src={HybridSolarIcon} alt="solar icon" width={25} />,
        section: "hybrid",
      },
      {
        text: "Heat Pump",
        icon: <img src={AirHeaterIcon} alt="air heat icon" width={25} />,
        section: "air",
      },
    ],
  },
  {
    text: "Contact Us",
    icon: <img src={ContactIcon} alt="contact icon" width={25} />,
    section: "contact",
  },
];

export default menuIcons;

import { Box, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";

import React from "react";
import image1 from "../assets/gallery/5.6kw @ merces.webp";
import image2 from "../assets/gallery/6kwongridsystem@ponda.jpg";
// import image3 from "../assets/gallery/16.kw solar Ongrid system.jpg";
import image3 from "../assets/gallery/borim.jpg";
import image4 from "../assets/gallery/Commercial air source heat pump.jpg";
// import image5 from "../assets/gallery/Commercial solar Water heater.jpg";
import image5 from "../assets/gallery/ribandar.jpg";
import image6 from "../assets/gallery/Domestic solar water heater.jpg";
import image7 from "../assets/gallery/Domestic solar water heater.webp";
import image8 from "../assets/gallery/Fpc  pressurised solar water heater.jpg";
import image9 from "../assets/gallery/Solar Ongrid setup.jpg";
import image10 from "../assets/gallery/Solar Ongrid system at margao.jpg";
import image11 from "../assets/gallery/ponda.jpg";
import image12 from "../assets/gallery/margaoHeater.jpg";
import image13 from "../assets/gallery/salgaokar.jpg";
const ImageGallery = (props) => {
  const handleScrollToContact = () => {
    props.handler("contact");
  };
  const itemData = [
    { url: image2, title: "Ponda", author: "6 KW Ongrid System" },
    { url: image1, title: "Merces", author: "5.6kw" },
    { url: image3, title: "Borim", author: "Solar setup" },
    { url: image7, title: "", author: "Domestic solar water heater" },
    { url: image9, title: "", author: "Solar Ongrid setup" },
    { url: image5, title: "Ribandar", author: "Solar setup" },
    { url: image10, title: "Margao", author: "Solar Ongrid system" },
    { url: image12, title: "Margao", author: "Heat Pump" },
    {
      url: image13,
      title: "Margao",
      author: "Vm Salgaonkar college of hotel management",
    },
    { url: image6, title: "", author: "Domestic solar water heater" },
    {
      url: image8,
      title: "",
      author: "Fpc pressurised solar water heater",
    },
    { url: image4, title: "", author: "Commercial air source heat pump" },
    { url: image11, title: "Ponda", author: "Solar setup" },
  ];
  return (
    <div className=" flex flex-col items-center pt-12 pb-16 bg-[#79AC78]">
      <div className="flex justify-center py-6 ">
        <h1 className="text-2xl font-normal text-white">
          OUR <span className="font-extrabold">WORK</span>
        </h1>
      </div>
      <Box sx={{ width: "95%", height: 450, overflowY: "scroll" }}>
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.url}>
              <img
                srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.url}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                position="below"
                sx={{
                  "& .MuiImageListItemBar-subtitle": {
                    paddingY: "5px",
                    textWrap: "wrap",
                    // lineHeight: "8px",
                    // fontSize: "10px",
                  },
                  "& .MuiImageListItemBar-titleWrap ": {
                    padding: "8px",

                    color: "white",
                  },
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <div className=" flex justify-center pt-14 ">
        <button
          onClick={handleScrollToContact}
          className="rounded-full text-lg bg-[#618264] px-6 py-2 text-white cursor-pointer shadow-lg hover:bg-[#79AC78] hover:text-black"
        >
          Call now
        </button>
      </div>
    </div>
  );
};

export default ImageGallery;

import React, { useState } from "react";
import TestimonialCard from "../components/TestimonialCard";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const data = [
  {
    comment: "Good work",
    name: "Nilesh ",
    designation: "CEO CubikTech",
  },
  {
    comment: "Best Guys!",
    name: "Kaushik",
    designation: "Founder FPV.in",
  },
  {
    comment: "Awesome service",
    name: "Viganesh",
    designation: "Digital Marketer",
  },
];

const Testimonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNextClick = () => {
    setActiveIndex((activeIndex + 1) % data.length);
  };

  const handlePreviousClick = () => {
    setActiveIndex((activeIndex - 1 + data.length) % data.length);
  };

  return (
    <div className="flex flex-col justify-center items-center pt-10 pb-14 bg-[#618264]">
      <div className="flex justify-center py-6">
        <h1 className="text-2xl font-normal text-white">
          WHAT <span className="font-extrabold">CLIENTS THINK</span>
        </h1>
      </div>
      <div className="flex">
        <button className=" px-2 " onClick={handlePreviousClick}>
          <KeyboardArrowLeftIcon
            sx={{
              color: "white",
              fontSize: "30px",
              "&:hover": {
                color: "black",
              },
            }}
          />
        </button>
        {data.length > 0 && (
          <TestimonialCard
            name={data[activeIndex].name}
            comment={data[activeIndex].comment}
            designation={data[activeIndex].designation}
          />
        )}

        <button className=" px-2 " onClick={handleNextClick}>
          <KeyboardArrowRightIcon
            sx={{
              color: "white",
              fontSize: "30px",
              "&:hover": {
                color: "black",
              },
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default Testimonial;

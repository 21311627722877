import React from "react";
import Logo from "../assets/logo.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const Contact = ({ handler }) => {
  const handleClick = (section) => {
    handler(section);
  };
  return (
    <div className="h-full w-full bg-[#79AC78] text-white">
      {/* TOP SECTION   */}
      <div className="flex flex-col items-center w-full py-10 ">
        {" "}
        {/* LOGO */}
        <div className="flex items-center h-[60px] mb-10 cursor-pointer">
          <img
            src={Logo}
            width={60}
            alt="SaiYog logo"
            onClick={() => handler("home")}
          ></img>
        </div>
        {/* 'CONTACT US' TEXT */}
        <div className="flex items-center h-[60px] py-10">
          <h1 className="text-2xl font-normal">
            CONTACT <span className="font-extrabold">US</span>
          </h1>
        </div>
      </div>

      {/* DETAILS SECTION */}
      <div className="flex flex-col items-center justify-around h-[650px] pt-8 bg-[#618264] rounded-t-full">
        {/* LOCATION  */}
        <div className="flex flex-col items-center text-base mt-10">
          <LocationOnOutlinedIcon
            className="mb-2"
            style={{ fontSize: "30px" }}
          />
          <p className="text-center w-[80%]">
            opp. G.V.Ms College Farmagudi, Donshiwado, Bandiwada, Ponda,
            <br /> Goa - 403401
          </p>
          <a
            href="https://www.google.com/maps/place/Saiyog+Solar/@15.4099256,73.9928434,15z/data=!4m6!3m5!1s0x3bbfbbfbd94204ad:0x53d2e9e0399bf7cc!8m2!3d15.4099256!4d73.9928434!16s%2Fg%2F11jd8cbjxv?entry=ttu"
            className="text-xs text-black underline"
          >
            Open map
          </a>
        </div>
        {/* CONTACT INFO  */}
        <div className="flex items-center justify-center w-full text-base">
          <PhoneAndroidOutlinedIcon
            className="mr-2"
            style={{ fontSize: "40px" }}
          />
          <div className="flex flex-col text-white gap-1 ">
            <a href="tel:91+ 7276227564" className="hover:text-black">
              91+ 7276227564
            </a>
            <a href="tel:91+ 7972827564" className="hover:text-black">
              91+ 7972827564
            </a>
          </div>
        </div>
        {/* SOCIAL  */}
        <div className="flex justify-between w-36 text-sm">
          <div className="flex flex-col items-center ">
            <a
              href="https://www.instagram.com/saiyog_solar?igsh=ZnpscjRlY3AwYQ=="
              className="hover:text-black"
            >
              <InstagramIcon
                className="mb-1 "
                sx={{
                  fontSize: "30px",
                }}
              />
            </a>
          </div>
          <div className="flex flex-col items-center">
            <a
              href="https://www.facebook.com/people/Saiyog-Solar-water-heater-solar-roof-top-solar-system/100063859243842/"
              className="hover:text-black"
            >
              <FacebookOutlinedIcon
                className="mb-1"
                style={{ fontSize: "30px" }}
              />
            </a>
          </div>
          <div className="flex flex-col items-center">
            <a href="https://wa.me/917276227564" className="hover:text-black">
              <WhatsAppIcon className="mb-1" style={{ fontSize: "30px" }} />
            </a>
          </div>
          <div className="flex flex-col items-center">
            <a href="mailto:saiyogsolar@gmail.com" className="hover:text-black">
              <MailOutlineIcon className="mb-1" style={{ fontSize: "30px" }} />
            </a>
          </div>
        </div>
        {/* QUICK LINKS  */}
        <div className="flex flex-col items-center">
          <h1 className="font-bold pb-2">Quick Links</h1>
          <button onClick={() => handleClick("about")} className="font-light">
            Services
          </button>
          <button onClick={() => handleClick("gallery")} className="font-light">
            Gallery
          </button>
          <button onClick={() => handleClick("home")} className="font-light">
            Home
          </button>
        </div>
        {/* COPYRIGHT  */}
        <div className="text-center text-xs">
          <p>2024 &copy; SaiYog Solar All Rights Reserved.</p>
          <p>Goa Energy Development Agency (Govt. of Goa)</p>
        </div>
        <div className="flex justify-end pr-8 lg:pr-14  w-full   ">
          <div className="border-2 border-white rounded-full hover:border-black">
            <KeyboardArrowUpIcon
              className=" cursor-pointer text-white"
              sx={{
                fontSize: "40px",
                "&:hover": {
                  color: "black",
                },
              }}
              onClick={() => handleClick("home")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

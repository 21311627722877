import React from "react";
import WaterHeaterIcons from "../assets/waterHeater.png";

const WaterHeater = () => {
  return (
    <div className="bg-gradient-to-r from-[#c2e5c5] via-gray-50 to-[#c2e5c5] flex flex-col  items-center justify-between h-full ">
      {/* SECTION 1  */}
      <div className="flex flex-col items-center pt-10 pb-0 w-full">
        {/* WATER HEATER IMG  */}
        <div className="py-8">
          <img src={WaterHeaterIcons} width={250} alt="" />
        </div>
        {/* 'SOLAR WATER HEATER' TEXT  */}
        <div className="text-center py-4">
          <h1 className="text-2xl font-extrabold text-[#79AC78] ">
            Solar Water <span className="text-black  ">Heater</span>
          </h1>
          <p className="text-sm ">Home & Business</p>
        </div>
      </div>
      {/* SECTION 2  */}
      {/* BULLET CAPSULES  */}
      <div className="flex flex-col items-center  gap-6 text-sm py-10  text-white w-full">
        <div className="flex justify-center py-2 w-[40%] md:w-[20%] rounded-lg bg-[#79AC78] ">
          <p>ETC & FPC</p>
        </div>
        {/* <div className="flex justify-center py-2 w-[55%] md:w-[30%] rounded-lg bg-[#79AC78] ">
          <p>General Servicing </p>
        </div> */}
        <div className="flex justify-center py-2 w-[70%] md:w-[40%] rounded-lg bg-[#79AC78] ">
          <p>Pressurized & Non Pressurized</p>
        </div>
        <div className="flex flex-col items-center py-2 w-[90%] md:w-[60%] rounded-lg bg-[#79AC78] ">
          <p>System Capacity Starts from - LPD (Liter/Day) </p>
          <p>100 ,150, 200, 250, 300 & 500 LPD </p>
        </div>
      </div>
    </div>
  );
};

export default WaterHeater;

// Logo.js
import React from "react";
import LogoImage from "../assets/logo.png";

const Logo = ({ handler }) => {
  return (
    <div className="flex cursor-pointer max-w-[140px] ">
      <img
        src={LogoImage}
        width={45}
        alt="Logo"
        onClick={() => handler("home")}
      />
    </div>
  );
};

export default Logo;

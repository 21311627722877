import React from "react";
// import Navbar from "../components/Navbar";
import SolarPanel from "../assets/windmill.png";
import ReactGA from "react-ga4";

const Home = (props) => {
  const handleScrollToContact = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked CALL NOW button",
    });
    props.handler("contact");
  };
  return (
    <main className="componentheight w-full overflow-y-hidden bg- bg-gradient-to-r from-[#c2e5c5] via-gray-50 to-[#c2e5c5]">
      <div className=" flex flex-col ">
        <div className="flex flex-col justify-center px-8 xl:px-10 pt-6 gap-2">
          <h1 className="text-4xl font-extrabold md:text-5xl">
            <span className="text-[#618264] ">SaiYog</span> Solar
          </h1>
          <h1 className="text-2xl font-semibold md:text-2xl">
            Solar Energy for Home & Businesses
          </h1>
          {/* <h1 className="text-4xl font-extrabold md:text-5xl">2024</h1> */}
          <span className="h-[6px] rounded-sm w-24 mt-2 mb-4 bg-[#618264] md:w-32  "></span>
        </div>
        <div className="relative w-full h-full lg:h-[300px] xl:h-[300px] aspect-square md:aspect-video xl:aspect-video ">
          <img
            src={SolarPanel}
            alt="Solar Panel Img"
            // className="max-xl:absolute -top-6 xl:relative xl:ml-auto xl:w-[1000px] "
            className="md:pl-[300px] md:-top-28 lg:pl-[550px] xl:right-10 xl:w-[1000px] xl:-top-28 -top-6 absolute"
          />
        </div>
        <div className=" flex justify-center relative -top-0 pb-16 md:-top-14 lg:-top-72 lg:justify-start lg:pl-8  xl:-top-72 xl:justify-start xl:pl-10 ">
          <button
            onClick={handleScrollToContact}
            className="rounded-full text-lg bg-[#618264] px-6 py-2 text-white cursor-pointer shadow-lg hover:bg-[#79AC78] "
          >
            Call now
          </button>
        </div>
      </div>
    </main>
  );
};

export default Home;

import React from "react";
import SolarPanel from "../assets/solarPanel.png";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
const HybridSolar = () => {
  return (
    <div className="componentheight bg-[#618264] text-white flex flex-col  items-center  justify-center ">
      {/* SECTION 1  */}
      <div className="flex flex-col items-center pt-10 pb-0">
        {/* SOLAR IMG  */}
        <div className="py-8">
          <img src={SolarPanel} width={300} alt="solar panel" />
        </div>
        {/* 'HYBRID SOLAR ENEGRY' TEXT  */}
        <div className="text-center py-4">
          <h1 className="text-2xl font-extrabold">
            Solar <span className="text-black ">ON GRID </span>System
          </h1>
          <p className="text-sm">Home & Business</p>
        </div>
      </div>
      {/* SECTION 2  */}
      <div className=" w-full flex flex-col justify-center items-center py-10">
        {/* 'ON GRID' TEXT  */}
        {/* <div className="flex flex-col items-center  w-[80%]  ">
          <SolarPowerIcon />
          <h1 className="text-base">
            SOLAR <span className="font-bold">ON GRID</span> SYSTEM
          </h1>
        </div> */}
        {/* BULLET CAPSULES  */}
        <div className="flex flex-col gap-6 text-sm py-4 mb-2 w-[85%] md:w-[45%]">
          <div className="flex justify-center py-2  rounded-full bg-[#79AC78] ">
            <p>Eco Friendly </p>
          </div>
          <div className="flex justify-center py-2  rounded-full bg-[#79AC78] ">
            <p>Govt. Subsidy Available</p>
          </div>
          <div className="flex justify-center py-2  rounded-full bg-[#79AC78] ">
            <p>Reduce or Zero Electricity Bill </p>
          </div>
          {/* <div className="flex justify-center py-2  rounded-full bg-[#79AC78] ">
            <p>Enjoy Uninterrupted Power Supply </p>
          </div> */}
          <div className="flex justify-center py-2  rounded-full bg-[#79AC78] ">
            <p>Excess Generation Purchase By Govt. </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HybridSolar;

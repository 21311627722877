import React from "react";
import { Box, Drawer } from "@mui/material";
import MenuItems from "./MenuItems";

const DrawerMenu = ({ openMenu, setOpenMenu, menuIcons, handler }) => {
  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          paddingTop: "60px",
          // paddingBottom: "50px",
          backgroundColor: "#d0e7d2",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      }}
      open={openMenu}
      onClose={() => setOpenMenu(false)}
      anchor="top"
    >
      <Box
        sx={{
          width: 250,
          paddingBottom: "50px",
        }}
        role="presentation"
        onClick={(event) => {
          // Prevent the drawer from closing when clicking on the dropdown menu
          if (!event.target.closest(".MuiListItemButton-root")) {
            setOpenMenu(false);
          }
        }}
        onKeyDown={(event) => {
          // Prevent the drawer from closing when pressing a key on the dropdown menu
          if (!event.target.closest(".MuiListItemButton-root")) {
            setOpenMenu(false);
          }
        }}
      >
        <MenuItems
          menuIcons={menuIcons}
          handler={handler}
          onItemClick={() => setOpenMenu(false)}
        />
      </Box>

      <Box
        sx={{
          width: 250,
          fontSize: "12px",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "10px",
          gap: "3px",
        }}
      >
        Powered by
        <a
          href="https://www.linkedin.com/in/dhananjay-pawar-688a82248/"
          style={{ textDecoration: "underline" }}
        >
          Vansh Technologies
        </a>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;

import React, { useEffect, useState, useRef } from "react";
import { HiBars3, HiChevronDown } from "react-icons/hi2";
import Logo from "./Logo";
import DrawerMenu from "./BurgerMenu";
import menuIcons from "./BurgerIcons";

const Navbar = ({ handler }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const dropdownRef = useRef(null);
  const parentRef = useRef(null);

  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const scrollingUp = prevScrollPos > currentScrollPos;
      setIsSticky(scrollingUp && currentScrollPos > 0);
      prevScrollPos = currentScrollPos;
    };

    const handleClickOutside = (event) => {
      if (
        !dropdownRef.current?.contains(event.target) &&
        !parentRef.current?.contains(event.target)
      ) {
        setOpenDropdown(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setOpenDropdown((prevState) => !prevState);
  };

  const handleItemClick = (item) => {
    if (item.children) {
      handleDropdownToggle();
    } else {
      handler(item.section);
    }
  };

  const handleChildItemClick = (section) => {
    handler(section);
    setOpenDropdown(false);
  };

  return (
    <nav
      className={`flex items-center justify-between w-full min-h-[60px] px-4 md:px-8 bg-gradient-to-r from-[#c2e5c5] via-gray-50 to-[#c2e5c5] transition-top duration-300 ease-in-out ${
        isSticky ? "sticky shadow-md top-0" : "top-[-60px]"
      }`}
    >
      <Logo handler={handler} />
      <div
        ref={parentRef}
        className="hidden md:flex justify-between items-center w-[30vw] md:w-[60%] lg:w-[50%] xl:w-[45%] relative"
      >
        {menuIcons.map((item) => (
          <div key={item.text} className="relative">
            <p
              onClick={() => handleItemClick(item)}
              className="flex items-center text-lg font-medium text-[#618264] hover:text-[#161f17] px-4 lg:px-6 xl:px-8 md:text-base lg:font-semibold cursor-pointer"
            >
              {item.text}
              {item.children && (
                <HiChevronDown
                  className={`ml-1 mt-1 ${
                    openDropdown ? "transform rotate-180" : ""
                  }`}
                />
              )}
            </p>
            {item.children && openDropdown && (
              <div
                ref={dropdownRef}
                className="absolute top-full w-[140%] mt-3  bg-gradient-to-r from-[#c2e5c5] via-gray-50  border-[1px]   rounded shadow-lg z-10"
              >
                {item.children.map((child) => (
                  <p
                    key={child.text}
                    onClick={() => handleChildItemClick(child.section)}
                    className="text-sm font-bold text-[#618264] px-4 py-2 hover:text-[#161f17] cursor-pointer"
                  >
                    {child.text}
                  </p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex md:hidden">
        <HiBars3
          className="text-2xl cursor-pointer"
          onClick={() => setOpenMenu(true)}
        />
      </div>
      <DrawerMenu
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        menuIcons={menuIcons}
        handler={handler}
      />
    </nav>
  );
};

export default Navbar;

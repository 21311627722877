import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const TestimonialCard = ({ name, comment, designation }) => {
  return (
    <Card
      sx={{
        minWidth: { xs: 250, sm: 400 },
        height: 220,
        boxShadow: "0 15px 10px rgb(0 0 0 / 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#D0E7D2",
      }}
    >
      <CardContent sx={{ padding: 3 }}>
        <Typography sx={{ mb: 1.5 }}>{comment}</Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {designation}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TestimonialCard;
